<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-card v-if="isOfferAccepted == true">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h5 class="bold d-inline">
                    You have accepted the assignment offer.
                  </h5>
                  <span class="ml-1">Wait for your Interview</span>
                </div>
              </div>
            </div>
            <!-- <div>
              <b-button variant="outline-primary"> Request Call </b-button>
            </div> -->
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card>
          <div
            class="d-lg-flex d-md-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar
                  size="lg"
                  src="../../assets/images/avatars/1-small.png"
                />
              </div>
              <div>
                <p class="my-0">Assignment Manager</p>
                <h5 class="d-inline">Generic Name</h5>
                <span> is available to solve your queries</span>
              </div>
            </div>
            <div class="mt-1 mt-lg-0 mt-md-0">
              <b-button variant="outline-primary"> Request Call </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card v-if="isOfferAccepted == false">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar
                  size="lg"
                  src="../../assets/images/avatars/1-small.png"
                />
              </div>
              <div>
                <p class="my-0">Project Manager</p>
                <h5 class="d-inline">Generic Name</h5>
                <span> is inviting you to apply for this project.</span>
              </div>
            </div>
            <div class="d-flex">
              <b-button
                v-b-modal.modal-accept
                variant="outline-primary"
                class="mr-2"
              >
                Accept
              </b-button>
              <b-button variant="outline-danger"> Decline </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-- <b-col lg="12" md="12" sm="12">
        <b-card v-if="isOfferAccepted == false">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h5 class="bold">
                  Your Profile Match for the Assignment is<span
                    class="text-primary ml-1"
                    >38 %</span
                  >
                </h5>
              </div>
            </div>
          </div>
        </b-card>
      </b-col> -->
    </b-row>

    <b-row>
      <b-col sm="10" md="5" lg="5" class="pr-2 mt-0">
        <!-- requirement component -->
        <!-- <Requirements /> -->
        <!-- hourly compensation  -->
        <!-- <HourlyCompensation /> -->
      </b-col>
      <b-col sm="10" md="7" lg="12" class="mt-0">
        <!-- assignment title component -->
        <!-- <AssignmentTitle /> -->
        <AssignmentDetails />
      </b-col>
    </b-row>
    <b-modal
      id="modal-accept"
      centered
      cancel-title="Close"
      title="Vetting Questions"
      ok-only
      size="lg"
      ok-title="Save Details"
      @ok="handleAcceptOffer"
    >
      <div class="px-lg-3 px-2">
        <b-row>
          <b-col cols="12" class="my-1">
            <h6 class="mt-1">
              Consectetur tempore est quia consequuntur ipsa, ?
            </h6>
            <b-form-textarea id="jobRes" placeholder="Write here..." rows="2" />
            <h6 class="mt-1">
              Lorem ipsum dolor, sit amet consectetur adipisicing.?
            </h6>
            <b-form-textarea id="jobRes" placeholder="Write here..." rows="2" />
            <h6 class="mt-1">Ipsum dolor sit amet consectetur consectetur ?</h6>
            <b-form-textarea id="jobRes" placeholder="Write here..." rows="2" />
            <h6 class="mt-1">
              Corrupti consectetur tempore est quia consequuntur ?
            </h6>
            <b-form-textarea id="jobRes" placeholder="Write here..." rows="2" />
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BRow,
  BFormTextarea,
} from 'bootstrap-vue';
import AnnualSalary from './AnnualSalary.vue';
import AssignmentTitle from './AssignmentTitle.vue';
import HourlyCompensation from './HourlyCompensation.vue';
import Requirements from './Requirements.vue';
import TermsAndCondition from './TermsAndCondition.vue';
import AssignmentDetails from './AssignmentDetails.vue';
export default {
  components: {
    BRow,
    BCard,
    BAvatar,
    BButton,
    BCol,
    BFormTextarea,
    Requirements,
    HourlyCompensation,
    AssignmentTitle,
    TermsAndCondition,
    AnnualSalary,
    AssignmentDetails,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isOfferAccepted: false,
      resident: [],
      required,
      email,
      selectCountryCode: '',
      organization: '',
      selectIndustry: [],
      countryCodeOptions: [
        { value: '+61', text: 'Australia(+61)' },
        { value: '+689', text: 'French(+689)' },
      ],
      industryOptions: [{ title: 'Option 1' }, { title: 'Option 2' }],
    };
  },
  computed: {},
  methods: {
    handleAcceptOffer() {
      this.isOfferAccepted = true;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>
